/* global Component, axios */
class HtmlSectionComponent extends Component{

    static name() {
        return "htmlSectionComponent";
    }

    static componentName() {
        return "htmlSectionComponent";
    }
    data() {
        return {
            htmlRaw:null,
            isFormHtml:false
        };
    }

    getProps() {
        return ["config","items","templateCode"];
    }

    getWatch() {
        return {
            'items': 'forceUpdate'
        };
    }

    mounted(){
        return function (){
            this.forceUpdate();
        };
    }

    async forceUpdate(){
        let templateCode = this.templateCode;
        if(this.items && this.items.length > 0)
            templateCode =  this.items[0].fields.ItemCode;

        if(templateCode) {
            let htmlRaw = await axios.get(`../ecommerce/api/getHtmlTemplate/${templateCode}`);
            this.isFormHtml  = htmlRaw.data.html.search("<form") == -1;
            this.htmlRaw = `${htmlRaw.data.html}`; //atob(htmlRaw.data.html);
        }
    }
    getMethods() {
        return {
            forceUpdate:this.forceUpdate
        };
    }

    getTemplate(){
        return `<div class="w-100">
                    <template v-if="isFormHtml">
                        <div :ref="templateCode" :id="templateCode" v-show="htmlRaw" :class="classContainer" v-html="htmlRaw"></div>
                    </template>
                    <template v-else>
                        <form :ref="templateCode" :id="templateCode+'Form'" v-html="htmlRaw">
                        </form>
                    </template>
                </div>`;
    }

}

HtmlSectionComponent.registerComponent();